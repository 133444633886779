<template>
  <v-card class="py-5" elevation="0">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >รายจ่ายภาษีรถ</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8">
          <v-row justify="center">
            <v-col cols="5" md="4" lg="3">
              <v-text-field
                label="ชื่อผู้จำหน่าย"
                outlined
                dense
                background-color="white"
                v-model="detail.dealer_name"
                disabled
              />
            </v-col>

            <v-col></v-col>

            <v-col cols="5" md="4" lg="3">
              <v-text-field
                class="mb-3"
                label="เลขที่เอกสาร"
                outlined
                dense
                background-color="white"
                v-model="detail.number"
                hide-details
                disabled
              />
              <v-text-field
                class="mb-3"
                label="รอบที่"
                outlined
                dense
                background-color="white"
                v-model="detail.project_name"
                hide-details
                disabled
              />
              <v-text-field
                class="mb-3"
                label="วันที่จ่าย"
                outlined
                dense
                background-color="white"
                v-model="done_datetime"
                hide-details
                disabled
              />
              <v-text-field
                class="mb-3"
                label="ผู้ใช้งาน"
                outlined
                dense
                background-color="white"
                v-model="detail.approval"
                hide-details
                disabled
              />
            </v-col>

            <v-col cols="12">
              <v-data-table
                class="elevation-1"
                :headers="headers"
                :items="detail.product"
                :loading="loading"
                :loading-text="$table_loading_text"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <!-- <template slot="item.index" slot-scope="item">
                  {{list.map(function(x) {return x._id; }).indexOf(item._id)+1+(itemsPerPage*(page-1))}} 
                </template> -->

                <template v-slot:[`item.car_type`]="{ item }">
                  {{
                    list.car_type_dict.filter((el) => {
                      return el.value == item.car_type;
                    })[0].name
                  }}
                </template>
                <template v-slot:[`item.extension_tax_fee`]="{ item }">
                  {{ item.extension_tax_fee | numeral('0,0') }}
                </template>
                <template v-slot:[`item.transportation_fee`]="{ item }">
                  {{ item.transportation_fee | numeral('0,0') }}
                </template>
                <template v-slot:footer>
                  <table-pagination
                    :page.sync="page"
                    :itemsPerPage.sync="itemsPerPage"
                    :length="pageCount"
                  />
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12">
              <v-row justify="space-between">
                <v-col cols="4"></v-col>
                <v-col cols="6" md="5" lg="4" xl="3" class="pr-7 fz-3">
                  <div class="mb-3">
                    <span class="primary--text" style="margin-left: -50px"
                      >สรุปรายจ่ายค่าภาษี</span
                    >
                  </div>
                  <div class="mb-3">
                    <span>ยอดต่อภาษีเรียกเก็บ</span>
                    <span class="float-right">{{
                      detail.total_price | numeral('0,0.00')
                    }}</span>
                  </div>
                  <div class="mb-3">
                    <span>ค่าช่องภาษีรถยนต์</span>
                    <span class="float-right">{{
                      detail.car_bribe | numeral('0,0.00')
                    }}</span>
                  </div>
                  <div class="mb-3">
                    <span>ค่าช่องภาษีรถจักรยานยนต์</span>
                    <span class="float-right">{{
                      detail.bike_bribe | numeral('0,0.00')
                    }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="primary--text">รวมทั้งสิ้น</span>
                    <span class="float-right">{{
                      detail.net_price | numeral('0,0.00')
                    }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="primary--text">ช่องทาง</span>
                    <span class="float-right">
                      {{ detail.payment.type == 1 ? 'เงินสด' : '' }}
                      {{ detail.payment.type == 2 ? 'โอนเงิน' : '' }}
                      <span v-if="detail.payment.type == 2">
                          {{ detail.payment.reference }}
                      </span>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="d-flex">
              <v-btn outlined class="px-5" color="grey" @click="close()"
                >ปิด</v-btn
              >
              <v-spacer />
              <v-btn
                class="px-10"
                color="primary"
                @click="downloadItemExcel()"
                :loading="loading"
                :disabled="loading"
              >
                พิมพ์ <v-icon right>mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from 'moment';
export default {
  props: {
    //   item: {
    //     type: Array,
    //     default: null,
    //   },
    // isUpdate: {
    //   type: Boolean,
    //   default: true,
    // },
  },
  data() {
    return {
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      list: {
        car_type_dict: [
          { value: '', name: '' },
          { value: 'CT1', name: '(รย.1) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
          { value: 'CT2', name: '(รย.2) รถยนต์นั่งส่วนบุคคลเกิน 7 คน' },
          { value: 'CT3', name: '(รย.3) รถยนต์บรรทุกส่วนบุคคล' },
          { value: 'CT4', name: '(รย.12) รถจักรยานยนต์ส่วนบุคคล' },
          { value: 'CT13', name: '(รย.17) รถจักรยานยนต์สาธารณะ' },
          { value: 'CT5', name: '(รย.13) รถแทรกเตอร์ที่ใช้ในการเกษตร' },
          { value: 'CT14', name: '(รย.15) รถใช้งานเกษตรกรรม' },
          {
            value: 'CT6',
            name: '(ขส.10) รถโดยสารประจำทาง (เลขทะเบียนข้างหน้าเป็น 10-19)',
          },
          {
            value: 'CT7',
            name: '(ขส.30) รถโดยสารไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 30-39)',
          },
          {
            value: 'CT8',
            name: '(ขส.40) รถโดยสารส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 40-49)',
          },
          {
            value: 'CT9',
            name: '(ขส.70) รถบรรทุกไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 70-79)',
          },
          {
            value: 'CT10',
            name: '(ขส.80) รถบรรทุกส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 50-59, 80-99)',
          },
          { value: 'CT11', name: '(รย.1EV) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
          { value: 'CT12', name: 'อื่นๆ' },
        ],
      },
      headers: [
        { text: 'ลำดับ', value: 'index' },
        { text: 'เลขทะเบียน', value: 'car_plate', filterable: false },
        { text: 'จังหวัด', value: 'car_province', filterable: false },
        { text: 'ประเภทรถ', value: 'car_type', filterable: false },
        { text: 'ค่าภาษี', value: 'extension_tax_fee', filterable: false },
        {
          text: 'ราคาที่ขนส่งเก็บ',
          value: 'transportation_fee',
          filterable: false,
        },
      ],
      detail: {
        dealer_name: '',
        product: [],
        total_price: 0,
        car_bribe: 0,
        bike_bribe: 0,
        net_price: 0,
      },
      done_datetime: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async downloadItemExcel() {
      this.loading = true;
      let filter = {
        expense_id: this.$route.params.id,
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/expense/download_expense_cartax`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          this.forceFileDownload(
            response,
            'รายจ่ายภาษีรถ_' + this.detail.project_name + '.xlsx'
          );
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    close() {
      // this.$emit('onClose');
      this.goBack();
    },
    async getData() {
      this.loading = false;
      let body = {
        token: this.$jwt.sign(
          { expense_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_by_id`, body)
        .then(async (res) => {
          let temp = [];
          this.detail = res.result;
          if (res.result.product == null) {
            this.detail.product = [];
          } else {
            this.detail.product.forEach((product, i) => {
              product.index = i + 1;
              temp.push(product);
            });
            this.detail.product = temp;
          }
          this.done_datetime = moment(res.done_datetime).format('DD/MM/YYYY');
        })
        .catch((err) => {
          console.log('err', err);
        });
      this.loading = false;
    },
    goBack() {
      this.$router.push({ name: 'expenses-cartax' });
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
